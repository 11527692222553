import React from 'react'
//import { useNavigate  } from 'react-router-dom'
import Layout from '../components/Layout'

const LoginPage = () => {

    return (
      <Layout>
        <form className='w-full max-w-sm flex flex-col space-y-6'>
            <div className='flex flex-col items-center mb-6 space-y-6'>
                <label
                    className='block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4'
                >
                    Scan QR to register.
                </label>
            </div>
        </form>
      </Layout>
  )
}


export default LoginPage
