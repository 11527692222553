import React, { useEffect, useRef, useState } from 'react'
//import Layout from '../components/Layout'
import { useParams } from 'react-router-dom'
import sendIcon from '../components/sendIcon'

const randomuuid = Date.now();
const WSSERVER="wss://chat.appstopus.com";

const ChatPage = () => {

    const [messages, setMessages] = useState([]);
    const [isConnectionOpen, setConnectionOpen] = useState(false)
    const [messageBody, setMessageBody] = useState("");
    const [reconn, setReconn] = useState(true);
    const [retry, setRetry] = useState(0);
    const [ chatroomname, setChatroomname] = useState("");
    //const [ otherstate, setOtherstate] = useState(0);
    const [ roomname, setRoomname] = useState("someone");
    //const { username } = useParams();
    const { room } = useParams();
    const ws = useRef();
    
    const checkconn = () => {
      if (!isConnectionOpen && !reconn) {
        setReconn(true)
        setRetry(retry+1);
      }
    }

    const sendMessage = () => {
        if(messageBody) {
            ws.current.send(
                JSON.stringify({
                    roomid : room,
                    uuid: randomuuid,
                    //sender: username,
                    body: messageBody,
                })
            );
            //if (messageBody==="CLOSE") { ws.current.close();  }
            setMessageBody("");
        }
    };


    useEffect(() => {
        console.log("Trying connection");
        ws.current = new WebSocket(WSSERVER+'/?roomid='+room);
        ws.current.onopen = () => {
            console.log("Connection Opened");
            setConnectionOpen(true);
            setReconn(false);
            ws.current.send(
              JSON.stringify({
                  roomid : room,
                  uuid: randomuuid,
                  //sender: username,
                  body: undefined,
              })
            );
        }
        ws.current.onmessage = (event) => {
          const data = JSON.parse(event.data);
          if (data.roomname && data.roomname!==roomname && data.uuid === randomuuid) {
            setRoomname(data.roomname);
          }
          if (data.chatroomname)
            setChatroomname(data.chatroomname);
  
          if (data)
            setMessages((_messages) => [..._messages, data]);
  
          //messages.forEach(message => {
          //  if (message.state === 0 && message.uuid !== randomuuid ) setOtherstate(message.state)
          //    else setOtherstate(1)
          //});
        };
        ws.current.onclose  = (event) => {
          console.log("Connection Closed");
          setConnectionOpen(false);
          setReconn(false);
          //delayreconn()    
        };
        return () => {
            console.log("Cleaning up...");
            ws.current.close();
        }
      }, [retry,room,roomname]);

    const scrollTarget = useRef(null);

    useEffect(() => {
        if(scrollTarget.current) {
            scrollTarget.current.scrollIntoView({behavior: "smooth"});
        }
    }, [messages.length]);

  return (
    
    <div className='w-full h-[calc(100dvh) flex flex-col items-center'>
      <header className='w-full flex flex-col justify-start items-center'>
        <h2 className='text-3xl font-bold'>{chatroomname}</h2> 
      </header>
      <main id="chat-view-container" className="flex flex-col w-full flex-1 justify-end overflow-y-auto p-3">
      {messages.map((message, index) => (
        <div key={index} className={`my-1 rounded py-1 w-1/3 text-white ${
          message.uuid === randomuuid ? "self-end bg-purple-600" : "bg-blue-600"
        } `}
        style={{display: message.body ? 'block' : 'none' }}
        >
          <div className="flex items-center">
            <div className="ml-2">
              <div className="flex flex-row">
                <div className="text-sm font-medium leading-5 text-gray-900">
                  {message.roomname} at
                </div>
                <div className="ml-1">
                  <div className="text-sm font-bold leading-5 text-gray-900">
                    { new Date(message.sentts).toLocaleTimeString("en-US", {timeStyle: "short"})}{" "}
                  </div>
                </div>
              </div>
              <div className="mt-1 text-sm font-semibold leading-5">
                {message.body}
              </div>
            </div>
          </div>
        </div>
      ))}
      <div ref={scrollTarget} />
    </main>
    <footer className="w-full justify-end">
        {
          (isConnectionOpen)?
          <p> You are online as <span className="font-bold">{roomname}</span></p>
          :<p> [Offline] Send a message to stay online</p>
        }
      <div className="flex flex-row">
        <input
          id="message"
          type="text"
          className="w-full border-2 border-gray-200 focus:outline-none rounded-md p-2 hover:border-purple-400"
          placeholder="Type your message here..."
          value={messageBody}
          onChange={(e) => setMessageBody(e.target.value)}
          onKeyDown={(e) => {if (e.key === 'Enter'){ sendMessage()} else {checkconn()} }}
          required
        />
        <button
          aria-label="Send"
          onClick={sendMessage}
          className="m-3"
          //disabled={!isConnectionOpen}
        >
          {sendIcon}
        </button>
      </div>
    </footer>
    </div>
  )
}

export default ChatPage
